import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

export const LoadAppScripts: React.FC = () => {
    const location = useLocation();
    const [scriptStarted, setScriptStarted] = useState(false);
  
    useEffect(() => {
      if (!scriptStarted) {
        if (process.env.REACT_APP_GA4_TRACK_ID) {
          ReactGA.initialize(process.env.REACT_APP_GA4_TRACK_ID);
        }
  
        setScriptStarted(true);
      }
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
    useEffect(() => {
      if (process.env.REACT_APP_GA4_TRACK_ID) {
        const locationUrl = `${location.pathname}${location.search}`;
        ReactGA.send({ hitType: "pageview", page: locationUrl });
      }
  
    }, [location]);
  
    return null;
  }