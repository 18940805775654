import { extendTheme } from "@chakra-ui/react";

const breakpoints = {
  sm: "376px",
  md: "768px",
  lg: "960px",
  xl: "1200px",
  "2xl": "1536px",
};

const theme = extendTheme({
  breakpoints,
  colors: {
    brand: {
      100: "#FFFFFF",
      200: "#1C2028",
      300: "#6D73F8",
    },
  },
  fontSizes: {
    h1: "130px",
    h2: "47px",
    h3: "43px",
    h4: "40px",
    h5: "32px",
    p1: "23px",
    p2: "20px",
    mh1: "94px",
    mh2: "85px",
    mh4: "60px",
    mh3: "33px",
    mh5: "29px",
    mp1: "34px",
    mp2: "30px",
    mp3: "20px",
    mp4: "14px",
  },

  components: {
    Button: {
      sizes: {
        md: {
          fontSize: "p1",
        },
      },
    },
  },
});

export default theme;
