import React from "react";
import i18next from "i18next";
import { Flex, Text } from "@chakra-ui/react";
import "../../styles/main.css";

export const HomePage: React.FC = () => {
  return (
    <Flex className="homeBackgroundImage font-face_bold">
      <Flex
        width={{ base: "100%", md: "100%", lg: "75%" }}
        className="homeContainer"
      >
        <Text
          className="homeTitle"
          fontSize={["mh4", "mh2", "mh1", "h1"]}
          color="brand.100"
        >
          {`${i18next.t("home:home.welcome")}`}
        </Text>
        <Text
          width={{ base: "90%", md: "90%", lg: "90%", xl: "55%" }}
          className="homeIntro"
          fontSize={["mh5", "mh3", "h3"]}
          color="brand.100"
        >
          {`${i18next.t("home:home.intro")}`}
        </Text>
      </Flex>
    </Flex>
  );
};
