import React from "react";
import i18next from "i18next";
import { Flex, Grid, Image, Text } from "@chakra-ui/react";
import keitaLogo from "../shared/assets/images/logos/keita_logo.png";
import linkedIn from "../shared/assets/images/icons/linkedIn.png";
import "../../styles/main.css";

export const Footer: React.FC = () => {
  //texts will later be converted to links

  return (
    <>
      <Flex className="footerContainerlogo">
        <Grid className="logoContainer">
          <Image
            width={{ base: "100%", sm: "70%", md: "60%", lg: "70%", xl: "35%" }}
            className="footerLogo"
            src={keitaLogo}
            alt="keita_logo"
          />
        </Grid>
        <Grid className="footerInfo">
          <Text
            className="infoText font-face_bold"
            fontSize={["mp4", "mp3", "h4"]}
            color="brand.300"
          >
            {`${i18next.t("footer:footer.infoText")}`}
          </Text>
          <Flex flexDirection="row">
            <Text
              className="font-face_regular mobile"
              fontSize={["mp4", "mp4", "p2"]}
              color="brand.300"
            >
              {`${i18next.t("footer:footer.links.follow")}`}
            </Text>
            <a
              href="https://www.linkedin.com/company/keita-mobility-factory/"
              target="_blank"
              rel="noreferrer"
              aria-label={`${i18next.t(
                "footer:footer.links.LinkedIn-aria-label"
              )}`}
            >
              <Image
                className="linkedInLogo"
                src={linkedIn}
                alt="linkedIn-icon"
              />
            </a>
          </Flex>
        </Grid>
      </Flex>
      <Flex className="footerContainerlinks">
        <Flex justifyContent="center">
          <Text
            className="footerLegal mobile font-face_regular"
            fontSize={["mp4", "mp4", "p3"]}
            color="brand.200"
          >
            {`${i18next.t("footer:footer.links.pp")}`}
          </Text>
          <Text
            className="footerLegal mobile font-face_regular"
            fontSize={["mp4", "mp4", "p3"]}
            color="brand.200"
          >
            {`${i18next.t("footer:footer.links.cookies")}`}
          </Text>
        </Flex>
        <Flex justifyContent="flex-end">
          <Text
            className="footerMenu font-face_regular"
            fontSize={["mp4", "mp4", "p3"]}
            color="brand.200"
          >
            {`${i18next.t("footer:footer.links.home")}`}
          </Text>
          <Text
            className="footerMenu font-face_regular"
            fontSize={["mp4", "mp4", "p3"]}
            color="brand.200"
          >
            {`${i18next.t("footer:footer.links.about")}`}
          </Text>
          <Text
            className="footerMenu font-face_regular"
            fontSize={["mp4", "mp4", "p3"]}
            color="brand.200"
          >
            {`${i18next.t("footer:footer.links.service")}`}
          </Text>
          <Text
            className="footerMenu font-face_regular"
            fontSize={["mp4", "mp4", "p3"]}
            color="brand.200"
          >
            {`${i18next.t("footer:footer.links.portfolio")}`}
          </Text>
          <Text
            className="footerMenu font-face_regular"
            fontSize={["mp4", "mp4", "p3"]}
            color="brand.200"
          >
            {`${i18next.t("footer:footer.links.contact")}`}
          </Text>
          <script
            id="CookieDeclaration"
            src="https://consent.cookiebot.com/7a852bb6-7ecc-44cb-b1c9-d4d137309d47/cd.js"
            type="text/javascript"
            async
          ></script>
        </Flex>
      </Flex>
    </>
  );
};
