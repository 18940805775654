import React from "react";
import i18next from "i18next";
import { Flex, Text } from "@chakra-ui/react";
import "../../styles/main.css";

export const AboutPage: React.FC = () => {
  return (
    <Flex flexDirection="column" className="gradientBackground font-face">
      <Flex
        width={{ base: "100%", sm: "70%", md: "60%", lg: "25%" }}
        className="aboutContainer"
      >
        <Text className="font-face_bold" fontSize="h2" color="brand.100">
          {`${i18next.t("about:about.infoText.title")}`}
        </Text>
        <Text
          fontSize="p1"
          className="aboutText font_face_regular"
          color="brand.100"
        >
          {`${i18next.t("about:about.infoText.intro_one")}`}
        </Text>
        <Text
          fontSize="p1"
          className="aboutText font_face_regular"
          color="brand.100"
        >
          {`${i18next.t("about:about.infoText.intro_two")}`}
        </Text>
        {/* <Button //link as button when there is a link
          className="aboutButton font_face_regular"
          bg="linear-gradient(90deg, rgba(118,175,252,1) 25%, rgba(131,161,251,1) 55%);"
          color="brand.100"
        >
          {`${i18next.t("about:about.about_us")}`}
        </Button> */}
      </Flex>
    </Flex>
  );
};
