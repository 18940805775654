import { default as i18n, default as i18next } from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import * as HEADER from "../app/header/locals";
import * as HOME from "../app/home/locals";
import * as ABOUT from "../app/about/locals";
import * as FOOTER from "../app/footer/locals"

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {},
    fallbackLng: "en",
    debug: false,
    defaultNS: "common",
    interpolation: {
      escapeValue: false,
    },
  });

i18next.addResourceBundle("en", HEADER.HEADER_NAMESPACE, HEADER.EN);
i18next.addResourceBundle("en", HOME.HOME_NAMESPACE, HOME.EN);
i18next.addResourceBundle("en", ABOUT.ABOUT_NAMESPACE, ABOUT.EN);
i18next.addResourceBundle("en", FOOTER.FOOTER_NAMESPACE, FOOTER.EN);
export default i18n;
