import { HomePage } from "../app/home/home";
import { Header } from "../app/header/header";
import { AboutPage } from "../app/about/about";
import { Footer } from "../app/footer/footer";
import { LoadAppScripts } from "../app/analytics/LoadAppScripts";

function KeitaHome() {
  return (
    <>
      <LoadAppScripts />
      <Header />
      <HomePage />
      <AboutPage />
      <Footer />
    </>
  );
}

export default KeitaHome;
