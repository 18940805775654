import React from "react";
import i18next from "i18next";
import { Flex, Text, Image } from "@chakra-ui/react";
import "../../styles/main.css";
import logo from "../shared/assets/images/logos/Logo_Desktop.svg";
import { HamburgerIcon } from "@chakra-ui/icons";
import twitch from "../shared/assets/images/icons/twitch.png";

export const Header: React.FC = () => {
  return (
    <Flex
      flexDirection="row"
      justifyContent="space-between"
      className="font-face_bold"
    >
      <Image className="desktop-logo" src={logo} alt="keita_logo" />
      <Flex
        width={{ base: "100%", md: "100%", lg: "40%", xl: "30%" }}
        className="headerLinksContainer"
      >
        <Text fontSize="p2" className="headerLinks" color="brand.100">
          {`${i18next.t("header:headerMenu.home")}`}
        </Text>
        <Text fontSize="p2" className="headerLinks" color="brand.100">
          {`${i18next.t("header:headerMenu.contact")}`}
        </Text>
        <Image className="twitch-icon" src={twitch} alt="twitchIcon" />
        <Text fontSize="p2" className="headerLinks" color="brand.100">
          {`${i18next.t("header:headerMenu.future")}`}
        </Text>
        <HamburgerIcon
          className="mobileMenu"
          alignContent="end"
          w={12}
          h={12}
          color="brand.100"
        ></HamburgerIcon>
      </Flex>
    </Flex>
  );
};
